import React, { Component } from "react";
import BaseComponent from "../base/BaseComponent";
import Post from "../atoms/Post";
import { get } from "@/redux/module/api";

interface PostListProps {
  resData: Post[];

  isLoading: boolean;
  pageNumber: number;
}
class PostList extends BaseComponent<
  {
    type: string;

    profileId?: string;
    animeId?: string;
    productCategoryId?: string;
    productId?: string;
    lotteryEventId?: string;

    onClick?: any;
  },
  PostListProps
> {
  observer: IntersectionObserver | null = null;
  loadingElementRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      resData: [],
      isLoading: true,
      pageNumber: 0,
    };

    this.loadingElementRef = React.createRef<HTMLDivElement>();
  }

  ALL_URL = "post/";
  USER_URL = "post/" + this.props.profileId;
  ANIME_URL = "post/anime/" + this.props.animeId;
  PRODUCT_CATEGORY_URL = "post/category/" + this.props.productCategoryId;
  PRODUCT_URL = "post/product/" + this.props.productId;

  MAIN_URL = "post/all";
  LOTTERY_EVENT_URL = "post/lottery/" + this.props.lotteryEventId;

  componentDidMount = async () => {
    super.componentDidMount();

    try {
      let res = null;
      if (this.props.type === "ALL") {
        res = await get(this.ALL_URL, {
          pageIndex: this.state.pageNumber,
          size: 10,
        });
      } else if (this.props.type === "USER") {
        res = await get(this.USER_URL, {
          pageIndex: this.state.pageNumber,
          size: 10,
        });
      } else if (this.props.type === "ANIME") {
        res = await get(this.ANIME_URL, {
          pageIndex: this.state.pageNumber,
          size: 10,
        });
      } else if (this.props.type === "PRODUCT_CATEGORY") {
        res = await get(this.PRODUCT_CATEGORY_URL, {
          pageIndex: this.state.pageNumber,
          size: 10,
        });
      } else if (this.props.type === "PRODUCT") {
        res = await get(this.PRODUCT_URL, {
          pageIndex: this.state.pageNumber,
          size: 10,
        });
      } else if (this.props.type === "MAIN") {
        res = await get(this.MAIN_URL, {});
      } else if (this.props.type === "LOTTERY_EVENT") {
        res = await get(this.LOTTERY_EVENT_URL, {
          pageIndex: this.state.pageNumber,
          size: 30,
        });
      }
      //   const res = await get("post/", {});

      if (res != null && res.status === 200) {
        const data = await res.data;

        this.setState({
          resData: data.resData,
          isLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
    if (this.props.type === "MAIN") {
      //   const interval = setInterval(async () => {
      //     window.scrollTo(0, window.scrollY + 65);
      //     //바닥까지 가면 다시 맨위로
      //     if (window.scrollY + window.innerHeight >= document.body.clientHeight) {
      //       window.scrollTo(0, 0);
      //     }
      //   }, 1000);
    }
    // Intersection Observer 시작
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null, // 스크롤 컨테이너를 사용하려면 해당 요소를 지정
      threshold: 1.0, // 관찰 대상 요소의 100%가 교차하면 콜백 함수 호출
    });

    if (this.loadingElementRef.current) {
      this.observer.observe(this.loadingElementRef.current);
    }
  };

  handleIntersection: IntersectionObserverCallback = (
    entries: IntersectionObserverEntry[]
  ) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
        // 교차 여부를 확인하는 요소가 loadingElementRef일 때만 로드
        if (entry.target === this.loadingElementRef.current) {
          this.fetchMorePostList();
        }
      }
    });
  };

  async fetchMorePostList() {
    let res = null;

    try {
      if (this.props.type === "ALL") {
        res = await get(this.ALL_URL, {
          pageIndex: this.state.pageNumber + 1,
          size: 10,
        });
      } else if (this.props.type === "USER") {
        res = await get(this.USER_URL, {
          pageIndex: this.state.pageNumber + 1,
          size: 10,
        });
      } else if (this.props.type === "ANIME") {
        res = await get(this.ANIME_URL, {
          pageIndex: this.state.pageNumber + 1,
          size: 10,
        });
      } else if (this.props.type === "PRODUCT_CATEGORY") {
        res = await get(this.PRODUCT_CATEGORY_URL, {
          pageIndex: this.state.pageNumber + 1,
          size: 10,
        });
      } else if (this.props.type === "PRODUCT") {
        res = await get(this.PRODUCT_URL, {
          pageIndex: this.state.pageNumber + 1,
          size: 10,
        });
      } else if (this.props.type === "MAIN") {
        res = await get(this.MAIN_URL, {});
      } else if (this.props.type === "LOTTERY_EVENT") {
        res = await get(this.LOTTERY_EVENT_URL, {
          pageIndex: this.state.pageNumber + 1,
          size: 30,
        });
      }

      const prevResData = this.state.resData;

      if (res != null && res.status === 200) {
        if (res.data.resData.length === 0) {
          return;
        }

        this.setState({
          resData: prevResData.concat(res.data.resData as Post[]),
          pageNumber: this.state.pageNumber + 1,
          isLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    return (
      <div>
        {this.state.resData && this.state.resData.length > 0 ? (
          this.state.resData.map((item: any, index: number) => {
            return (
              <Post
                resData={item}
                key={index}
                type={this.props.type}
                onClick={this.props.onClick ? this.props.onClick : null}
              />
            );
          })
        ) : this.props.type === "0" ? (
          <div>등록된 게시글이 없습니다.</div>
        ) : (
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontFamily: "normal",
                fontSize: "0.7rem",
                // marginBottom: "20px",
              }}
            >
              등록된 게시글이 없습니다.
            </span>
          </div>
        )}

        {this.props.type !== "MAIN" && (
          <div
            ref={this.loadingElementRef}
            style={{
              height: "20px",
              margin: "10px",
              background: "transparent",
            }}
          >
            {/* 여기에 교차 여부를 감지할 요소(일반적으로 로딩 스피너 또는 힌트 메시지 등)를 추가할 수 있습니다. */}
          </div>
        )}
      </div>
    );
  }
}

export default PostList;
