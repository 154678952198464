import { greyBoxBorderColor, primary, primaryText } from "@/styles/theme";
import BaseComponent from "../base/BaseComponent";
import Box from "./CustomBox";
import { addDot, getRGBAValue } from "@/Utils/Utils";
import CustomBox from "./CustomBox";

class InfoPostBox extends BaseComponent<{
  type: string;

  infoCompactThumbnailUrl: string;
  infoTitle: string;
  infoContent: string;

  infoPrice?: string;
  infoUrl?: string;
}> {
  render() {
    return (
      <Box
        style={{
          display: "inline-block",
          width: "85%",
          backgroundColor: getRGBAValue("#ffffff", 0.6),
          fontSize: "0.8rem",
          marginLeft: "5px",
          padding: "3px",
          marginRight: "15px",
          borderRadius: "4px",
        }}
        sx={{
          boxShadow: "0 0px 3px 2px " + getRGBAValue(greyBoxBorderColor, 0.5),
          border: 2,
          borderColor: greyBoxBorderColor,
        }}
      >
        <a href={this.props.infoUrl}>
          <div
            style={{
              display: "flex",
              width: "100%",
              paddingTop: "3px",
              paddingBottom: "3px",
            }}
          >
            <div
              style={{
                width: "100px",
                display: "inline-block",
                padding: "3px",
                paddingLeft: "10px",
              }}
            >
              <img
                src={this.props.infoCompactThumbnailUrl}
                style={{
                  width: this.props.type === "1" ? 40 : 70,
                  height: this.props.type === "1" ? 40 : 70,
                }}
              />
            </div>
            <div
              style={{
                alignItems: "left" /* 수직 중앙 정렬 */,
                display: "inline-block",
                width: "100%",
                flexGrow: 1,
                justifyContent: "left",
                textAlign: "left",
                padding: "3px",
                paddingLeft: "5px",
              }}
            >
              <div
                style={{
                  fontSize: "0.6rem",
                  textAlign: "left",
                  padding: "3px",
                  color: "#4b4b4b",
                }}
              >
                <div
                  style={{
                    padding: "3px",
                  }}
                >
                  <span
                    style={{
                      color: primaryText,
                      fontSize: this.props.type === "1" ? "0.9rem" : "0.7rem",
                    }}
                  >
                    <b>
                      {this.props.type === "3" && "💜추첨 이벤트💜"}
                      <br></br>
                      <br></br> 《{" "}
                      {this.props.infoTitle ? this.props.infoTitle : ""} 》
                    </b>
                  </span>
                </div>
                <div
                  style={{
                    padding: "3px",
                  }}
                >
                  <span
                    style={{
                      color: "#4d4d4d",

                      fontSize: "0.7rem",
                    }}
                  >
                    {this.props.infoContent ? this.props.infoContent : ""}
                  </span>
                </div>
                <div
                  style={{
                    padding: "5px",
                  }}
                >
                  <span style={{ color: primaryText }}>
                    {this.props.infoPrice
                      ? addDot(parseInt(this.props.infoPrice)) + "원"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </Box>
    );
  }
}

export default InfoPostBox;
